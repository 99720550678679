body {
  background: #f6f6ef;
  font-family: 'Exo 2', sans-serif;
  color: #3f4045;
}

a {
  color: #171b23;
  font-weight: bold;
}

.accent {
  position: absolute;
}

.content {
  position: relative;
  padding: 82px;
}

.content .logo {
  margin-bottom: 48px;
}

.content .description h1 {
  font-size: 26px;
  line-height: 34px;
  margin: 0 0 24px 0;
}

.content .description h2 {
  font-size: 22px;
  line-height: 30px;
  margin: 0 0 20px 0;
}

.content .description p {
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0 0 0;
}

.socialLinks {
  margin: 48px 0 0 0;
}

@media (max-width: 600px) {
  .content {
    padding: 45px;
  }
}

@media (max-width: 500px) {
  .socialLinks > div {
    margin-right: 0;
  }

  .socialLinks > div+div {
    display: block;
    margin-top: 24px;
  }
}

@media (max-width: 400px) {
  .content .description h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .content .description h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .content .description p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 340px) {
  .content {
    padding: 48px;
  }

  .logo {
    max-width: 230px;
  }
}

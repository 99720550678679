body {
  background: #f6f6ef;
  font-family: 'Exo 2', sans-serif;
  color: #3f4045;
}

a {
  color: #171b23;
  font-weight: bold;
}

.Application_accent__2RaLU {
  position: absolute;
}

.Application_content__2SYiB {
  position: relative;
  padding: 82px;
}

.Application_content__2SYiB .Application_logo__13zST {
  margin-bottom: 48px;
}

.Application_content__2SYiB .Application_description__1qOdA h1 {
  font-size: 26px;
  line-height: 34px;
  margin: 0 0 24px 0;
}

.Application_content__2SYiB .Application_description__1qOdA h2 {
  font-size: 22px;
  line-height: 30px;
  margin: 0 0 20px 0;
}

.Application_content__2SYiB .Application_description__1qOdA p {
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0 0 0;
}

.Application_socialLinks__2qocz {
  margin: 48px 0 0 0;
}

@media (max-width: 600px) {
  .Application_content__2SYiB {
    padding: 45px;
  }
}

@media (max-width: 500px) {
  .Application_socialLinks__2qocz > div {
    margin-right: 0;
  }

  .Application_socialLinks__2qocz > div+div {
    display: block;
    margin-top: 24px;
  }
}

@media (max-width: 400px) {
  .Application_content__2SYiB .Application_description__1qOdA h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .Application_content__2SYiB .Application_description__1qOdA h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .Application_content__2SYiB .Application_description__1qOdA p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 340px) {
  .Application_content__2SYiB {
    padding: 48px;
  }

  .Application_logo__13zST {
    max-width: 230px;
  }
}

.Accent_primary__2Onu9 {
  fill: #d2da9e;
}

.Accent_secondary__7smyO {
  fill: #fbfbfb;
}

.Logo_logo__12X0B {
  display: block;
  width: 100%;
  max-width: 240px;
  min-width: 224px;
}

.SocialLink_container__1T6Ce {
  display: inline-block;
  margin-right: 24px;
}

.Icon_container__2lGPF {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}

.Icon_icon__1tK5k {
  width: 18px;
}

